import {Injectable, OnDestroy} from "@angular/core";
import {Subject, BehaviorSubject, fromEvent} from "rxjs";
import {takeUntil, debounceTime} from "rxjs/operators";
import {Router} from "@angular/router";

// Menu
export interface Menu {
    headTitle1?: string;
    headTitle2?: string;
    path?: string;
    title?: string;
    icon?: string;
    type?: string;
    badgeType?: string;
    badgeValue?: string;
    active:boolean;
    bookmark?: boolean;
    children?: Menu[];
}

@Injectable({
    providedIn: "root",
})
export class NavService implements OnDestroy {
    private unsubscriber: Subject<any> = new Subject();
    public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

    // Search Box
    public search: boolean = false;

    // Language
    public language: boolean = false;

    // Mega Menu
    public megaMenu: boolean = false;
    public levelMenu: boolean = false;
    public megaMenuColapse: boolean = window.innerWidth < 1199;

    // Collapse Sidebar
    public collapseSidebar: boolean = window.innerWidth < 991;

    // For Horizontal Layout Mobile
    public horizontal: boolean = window.innerWidth >= 991;

    // Full screen
    public fullScreen: boolean = false;

    constructor(private router: Router) {
        this.setScreenWidth(window.innerWidth);
        fromEvent(window, "resize")
            .pipe(debounceTime(1000), takeUntil(this.unsubscriber))
            .subscribe((evt: any) => {
                this.setScreenWidth(evt.target.innerWidth);
                if (evt.target.innerWidth < 991) {
                    this.collapseSidebar = true;
                    this.megaMenu = false;
                    this.levelMenu = false;
                }
                if (evt.target.innerWidth < 1199) {
                    this.megaMenuColapse = true;
                }
            });
        if (window.innerWidth < 991) {
            // Detect Route change sidebar close
            this.router.events.subscribe((event) => {
                this.collapseSidebar = true;
                this.megaMenu = false;
                this.levelMenu = false;
            });
        }
    }

    ngOnDestroy() {
        // this.unsubscriber.next();
        this.unsubscriber.complete();
    }

    private setScreenWidth(width: number): void {
        this.screenWidth.next(width);
    }

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    MENUITEMS: Menu[] = [
        /*{
            headTitle1: "Pages",
        },*/
        {
            path: "/dashboard",
            icon: "fas fa-home",
            title: "Dashboard",
            type: "link",
            //@ts-ignore
            availableTo: ["SUPERADMIN"],
            bookmark: true
        },
        // {
        //     icon: "fa-user-shield fas",
        //     type: "sub",
        //     badgeType: "light-primary",
        //     title: "Employee",
        //     active: false,
        //     //@ts-ignore
        //     availableTo: ["SUPERADMIN"],
        //     children: [
        //         {
        //             path: "/employee/add",
        //             title: "Add",
        //             type: "link",
        //             //@ts-ignore
        //             availableTo: ["SUPERADMIN"],
        //         },
        //         {
        //             path: "/employee",
        //             title: "List",
        //             type: "link",
        //             //@ts-ignore
        //             availableTo: ["SUPERADMIN"],
        //         }
        //     ],
        // },
        {
            icon: "fa-user-shield fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Career",
            active: false,
            //@ts-ignore
            availableTo: ["SUPERADMIN"],
            children: [
                {
                    path: "/career/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPERADMIN"],
                },
                {
                    path: "/career",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPERADMIN"],
                }
            ],
        },
        {
            icon: "fa-user-shield fas",
            type: "sub",
            badgeType: "light-primary",
            title: "Blog",
            active: false,
            //@ts-ignore
            availableTo: ["SUPERADMIN"],
            children: [
                {
                    path: "/blog/add",
                    title: "Add",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPERADMIN"],
                },
                {
                    path: "/blog",
                    title: "List",
                    type: "link",
                    //@ts-ignore
                    availableTo: ["SUPERADMIN"],
                }
            ],
        },
        {
            path: "/contact-us",
            icon: "fas fa-home",
            title: "Contact ",
            type: "link",
            //@ts-ignore
            availableTo: ["SUPERADMIN"],
            bookmark: true
        },
        {
            path: "/career-data",
            icon: "fas fa-home",
            title: "career Data ",
            type: "link",
            //@ts-ignore
            availableTo: ["SUPERADMIN"],
            bookmark: true
        },

    ];

    // Array
    items = new BehaviorSubject<Menu[]>(this.MENUITEMS);
}
