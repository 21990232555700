import {Routes} from "@angular/router";

export const content: Routes = [
    {
        path: "dashboard",
        loadChildren: () => import("../../components/dashboard/dashboard.module").then((m) => m.DashboardModule)
    },
    {
        path: "career",
        loadChildren: () => import("../../components/career/career.module").then((m) => m.CareerModule)
    },
    {
        path: "blog",
        loadChildren: () => import("../../components/blog/blog.module").then((m) => m.BlogModule)
    },
    {
        path: "contact-us",
        loadChildren: () => import("../../components/contact-us/contact-us.module").then((m) => m.ContactUsModule)
    },
    {
        path: "career-data",
        loadChildren: () => import("../../components/career-data/career-data.module").then((m) => m.CareerDataModule)
    }
];
