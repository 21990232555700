import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

    static get ADMIN_URL(): string {
        return environment.appUrl + 'admin/';
    }

    static get API_URL(): string {
        return environment.appUrl + 'api/';
    }

    static get LOGIN(): string {
        return AppUrl.ADMIN_URL + 'login';
    }

    static get SELF(): string {
        return AppUrl.ADMIN_URL + 'self';
    }

    static UPDATE_SELF(): string {
        return AppUrl.ADMIN_URL + 'update-self';
    }

    static UPDATE_PASSWORD(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'update-password/' + uniqueId;
        }
    }

    static UPDATE_STATUS(uniqueId?): string {
        if (uniqueId) {
            return AppUrl.ADMIN_URL + 'head-counsellor-status/' + uniqueId;
        }
    }

    static LOGOUT(): string {
        return AppUrl.ADMIN_URL + 'logout';
    }

    static FILE_UPLOAD(): string {
        return AppUrl.ADMIN_URL + 'file-upload';
    }

    static STATES(): string {
        return AppUrl.ADMIN_URL + 'states';
    }

    static USER(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'user/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'user';
        }
    }

    static SELF_PROFILE_PASSWORD(): string {
        return AppUrl.ADMIN_URL + 'update-user-password';
    }

    static BLOGS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'blogs';
        }
    }

    static BLOGS_COUNT(): string {
        return AppUrl.ADMIN_URL + 'blogs-count';
    }

    static BLOG_STATUS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-status/' + id;
        }
    }

    static BLOG_BANNER_UPDATE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-banner/' + id;
        }
    }

    static BLOG_COVER_UPDATE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-cover/' + id;
        }
    }

    static BLOG_CONTENT_UPDATE(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'blogs-content/' + id;
        }
    }

    static CAREERS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'career/' + unique_id;
        } else {
            return AppUrl.ADMIN_URL + 'career';
        }
    }
    static JOBOPENING(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'job-opening/' + id;
        } else {
            return AppUrl.ADMIN_URL + 'job-opening';
        }
    }

    static CONTACTUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.API_URL + 'contact-us/' + unique_id;
        } else {
            return AppUrl.API_URL + 'contact-us';
        }
    }
    static CAREERSDATA(unique_id?): string {
        if (unique_id) {
            return AppUrl.API_URL + 'career/' + unique_id;
        } else {
            return AppUrl.API_URL + 'career';
        }
    }



    static CAREERS_COUNT(): string {
        return AppUrl.ADMIN_URL + 'career-count';
    }

    static CAREER_STATUS(unique_id?): string {
        if (unique_id) {
            return AppUrl.ADMIN_URL + 'career-status/' + unique_id;
        }
    }

    static    JOBOPENING_STATUS(id?): string {
        if (id) {
            return AppUrl.ADMIN_URL + 'job-opening-status/' + id;
        }
    }
}
